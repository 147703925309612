<template>
    <div class="Client">
        <!-- BEGIN: Content-->
        <div class="app-content content ">
            <div class="content-overlay"></div>
            <div class="header-navbar-shadow"></div>
            <div class="content-wrapper container-xxl p-0">
                <div class="row mt-3">
                    <div class="col-md-6 col-xl-6 col-12 text-start">
                        <h4>Project</h4>
                        <nav style="--bs-breadcrumb-divider: url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);"
                            aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Library</li>
                            </ol>
                        </nav>
                    </div>
                    <div class="col-md-6 col-xl-6 col-12 text-end">
                        <button type="button" class="btn btn-outline-success  btn-sm" style="font-size:18px;"><i
                                class="fa fa-envelope" aria-hidden="true"></i></button>

                        <button type="button" class="btn btn-outline-success   btn-sm" style="font-size:18px;"><i
                                class="fa fa-download" aria-hidden="true"></i></button>


                        <button type="button" class="btn  btn-outline-success  btn-sm" @click="print"
                            style="font-size:18px;"> <i class="fa fa-file" aria-hidden="true"></i></button>
                    </div>
                </div>


                <div class="row">
    <div class="col-3 p-3">
        <input type="number" class="form-control" v-model="totalAmt"><br>
        <button @click="covertNumberTowor()" class="btn btn-sm bg-primary text-white">Convert</button>
    </div>
</div>

                <div class="row" id="printableArea">
                    <div class="col-xl-12 col-md-12 col-lg-12 col-12">
                        <div class="card">
                            <div class="card-body p-3 shadow-lg  rounded">
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-start" strong
                                        style="color:black;">
                                        <div class="logo-img">
                                            <img style="width:25%" src="/assets/image/vakilgiri.png">
                                        </div>
                                        <h4 class="mt-2"><strong style="color:black;  text-transform: uppercase;" v-if="userData">{{userData.name}}</strong></h4>
                                        <div> <strong>Address: </strong><span v-if="userData">{{userData.address}}</span></div>

                                        <div> <strong>[M]: </strong><span v-if="userData"> +91-{{userData.phone}},
                                            </span><strong>[E]:</strong> <span v-if="userData"> {{userData.email}}, </span></div>
                                        <div> <strong>GSTIN: </strong><span>05AAFCD8897C2ZE</span></div>
                                    </div>
                                    <div class="col-xl-6 col-md-6 col-lg-6 col-6 text-end">
                                        <h4 style="color:#00364f;font-size:2.5vw;"><b>RECEIPT</b></h4>
                                        <div><span style="color:black;"><b># REC-1011-22 </b></span></div>
                                        <div><h3><b class="text-danger">OVERDUE</b></h3></div>
                                        <div class="mt-2">
                                            <div><b style="color:black;font-size:1vw;">BILL TO</b> <br><span
                                                        style="color:black;font-size:1.5vw;margin-top:-5px;"><b v-if="invoiceData.client">{{invoiceData.client.fname}} {{invoiceData.client.lname}}</b></span>
                                            </div>


                                        </div>
                                        <div><span style="color:black;"  v-if="invoiceData.client">{{invoiceData.client.address}},{{invoiceData.client.address2}} </span></div>
                                        <div> <span style="color:black;" v-if="invoiceData.client"> {{invoiceData.client.pin}}</span></div>

                                    </div>

                                </div>
                                <hr>
                           
                                <!-- <div class="row mt-1">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="margin-top:7px;">

                                        <div class="container-fluid table-scroll">
                                            <table class="table table-hover table-sm">

                                                <tr class="text-white table-wrapper-scroll-y  rounded-circle"
                                                    style="background-color:black;">
                                                    <th>Date</th>
                                                    <th>Invoice</th>
                                                    <th>Business</th>
                                                    <th>Project</th>

                                                    <th>Mode</th>

                                                    <th class="text-end">Amount</th>

                                                </tr>

                                                <tbody>



                                                    <tr style="color:black;">
                                                        <td style="width:10%">21-09-2022</td>
                                                        <td style="width:10%">INV4141</td>
                                                        <td style="width:30%">VAKILGIRI</td>
                                                        <td style="width:27%">E-commerce Website</td>

                                                        <td style="width:13%">Cash</td>


                                                        <td style="width:10%" class="text-end">Rs.20000.00</td>

                                                    </tr>
                                                    <tr style="color:black;">
                                                        <td style="width:10%">21-09-2022</td>
                                                        <td style="width:10%">INV4141</td>
                                                        <td style="width:30%">VAKILGIRI</td>
                                                        <td style="width:27%">E-commerce Website</td>

                                                        <td style="width:13%">Cash</td>


                                                        <td style="width:10%" class="text-end">Rs.20000.00</td>

                                                    </tr>
                                                    <tr style="color:black;">
                                                        <td style="width:10%">21-09-2022</td>
                                                        <td style="width:10%">INV4141</td>
                                                        <td style="width:30%">VAKILGIRI</td>
                                                        <td style="width:27%">E-commerce Website</td>

                                                        <td style="width:13%">Cash</td>


                                                        <td style="width:10%" class="text-end">Rs.20000.00</td>

                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div> -->

                                <div class="row mt-1">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="margin-top:7px;">

                                        <div class="container-fluid table-scroll">
                                            <table class="table table-hover table-sm">

                                                <tr class="text-white table-wrapper-scroll-y  rounded-circle"
                                                    style="background-color:black;">
                                                    <th>Date</th>
                                                    <th>Invoice</th>
                                                    <th>Business</th>
                                                    <th>Project</th>
                                                    <th>Mode</th>
                                                    <th class="text-end">Amount</th>

                                                </tr>

                                                <tbody>



                                                    <tr style="color:#00364f;">
                                                        <td style="width:10%"><span v-if="invoiceData">{{invoiceData.date}}</span></td>
                                                        <td style="width:10%"><span v-if="invoiceData">{{invoiceData.id}}</span></td>

                                                        <td style="width:15%">1</td>
                                                      
                                                        <td style="width:49%"><span v-if="invoiceData.service">{{invoiceData.service.name}}</span></td>
                                                        <td style="width:8%">Cash</td>
                                                        <td style="width:8%" class="text-end">Rs.{{invoiceData.serviceCharge}}</td>

                                                    </tr>
                                              

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2"
                                    style="background-color:#F2F2F2;padding:5px 10px 5px 10px;border-radius:10px;">
                                    <div class="col-xl-8 col-lg-8 col-md-8 col-8">
                                        <b style="color:black;"> In words: <span  id="result" v-if="totalAmt">Rs. {{inWords}}</span></b>
                                      
                                    </div> 
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-2  text-end">
                                        <b style="color:black;"> Total</b>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-2  text-end">
                                        <b style="color:black;" id="value">Rs.{{totalAmt}}</b>
                                    </div>

                                   

                                </div>

                                <div class="row ">
                                    <div class="col text-center">
                                        <p style="color:black;">This is Computer Generate Invoice No Signature Required
                                        </p>
                                    </div>
                                </div>
                                <hr>
                                <div class="row" style="color:black;">
                                    <div class="col  text-start">
                                        In association with <img style="width:15%"
                                            src="https://www.micromuneem.com/wp-content/uploads/2022/02/MicroMuneem-Logo-300x111.webp"
                                            alt="">
                                    </div>
                                    <div class="col text-end">
                                        Powerd by <img style="width:15%" src="/assets/image/vakilgiri.png" alt="">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>


</template>

<script>
// import Banner from '../../../components/retailer/comman/Banner.vue'
// import Form from 'vform'
// import jQuery from "jquery";
// let $ = jQuery
export default {
    name: 'Packages',
    retailer_id: '',
    components: {

    },
    data() {
        return {

            inWords: '',
            invoiceData: {},
           totalAmt: null,
           number:null,
           ones : ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
           tens : ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'],
           scales : ['','','', 'hundred ','thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'],

        }
    },
    methods: {
        countDig(totalAmt){
            var n = totalAmt
            var count = 0;
           if (n >= 1) ++count;
           while (n / 10 >= 1) {
           n /= 10;
           ++count;
        }
        return count;
        // this.covertNumberTowor(count)
        },

        atOnse19(amt){
            return this.ones.at(amt);
        },
        atTese99(amt){
           var n = 0;
            var res = amt;
            n = parseInt(res / 10);
                amt = parseInt(res % 10);
               var  txt1 = this.tens.at(n) 
               if(amt<20){
                 txt1 = txt1+' '+this.atOnse19(amt)
                 return txt1
                }else{
                    return txt1
                }
        },
        scales999(amt){
            let count = this.countDig(amt)
            var res = amt
            var n = parseInt(res / 100)
            var  txt1 = this.ones.at(n) 
            amt = parseInt(res % 100);
                txt1 = this.atOnse19(n) +' '+this.scales.at(count) 
                // console.log(amt)
                if(amt>19 && amt<100){
                    txt1 =  txt1+' '+this.atTese99(amt)
                    return txt1
                } else if(amt<20){
                 txt1 = txt1+' '+this.atOnse19(amt)
                 return txt1
                }else{
                    return txt1
                }
        },
        valTo9999(amt){
            let count = this.countDig(amt)
            var res = amt
            var n = parseInt(res / 1000)
            var  txt1 = this.ones.at(n) 
            amt = parseInt(res % 1000);
                txt1 = this.atOnse19(n) +' '+this.scales.at(count) 
                if(amt > 99 && amt < 1000 ){
                   txt1 = txt1+' '+this.scales999(amt) 
                   return txt1
                } else if(amt>19 && amt<100){
                    txt1 = txt1 +' '+ this.atTese99(amt) 
                    return txt1
                }else if(amt<20){
                 txt1 = txt1+' '+this.atOnse19(amt)
                 return txt1
                }else{
                    return txt1
                }
        },
        valTo99999(amt){
            let count = this.countDig(amt)
            if(count == 4 || count == 5){
                count = 4 
            }
            var res = amt
            var n = parseInt(res / 1000)
            var  txt1 = this.ones.at(n) 
            amt = parseInt(res % 1000);
                txt1 = (n < 20) ? this.atOnse19(n) : this.atTese99(n) +' '+this.scales.at(count) 
             if(amt > 99 && amt < 1000 ){
                   txt1 = txt1+' '+this.scales999(amt) 
                   return txt1
                } else if(amt>19 && amt<100){
                    txt1 = txt1 +' '+ this.atTese99(amt) 
                    return txt1
                }else if(amt<20){
                 txt1 = txt1+' '+this.atOnse19(amt)
                 return txt1
                }else{
                    return txt1
                }
        },
        covertNumberTowor(){ 
            let count = this.countDig(this.totalAmt)
            if(this.totalAmt < 20){
                count = 1
            }
      switch(count){
            case 1:
                 this.inWords =  this.atOnse19(this.totalAmt)
                 break;
            case 2:
                 this.inWords =  this.atTese99(this.totalAmt)
                 break;
            case 3:
                this.inWords =  this.scales999(this.totalAmt)
                break;
            case 4:
                this.inWords =  this.valTo9999(this.totalAmt)
                break;
            case 5:
                    this.inWords =  this.valTo99999(this.totalAmt)
                     break;
           
            default:
            this.inWords = this.totalAmt


    }

        },
        print() {
            // alert('hello')
            // Get HTML to print from element
            const prtHtml = document.getElementById('printableArea').innerHTML

            // Get all stylesheets HTML
            let stylesHtml = ''
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML
            }
            // Open the print window
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')
            WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
            <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
              ${stylesHtml}
          </head>
          <body>
              ${prtHtml}
          </body>
        </html>`)

            WinPrint.document.close()
            WinPrint.focus()
            WinPrint.print()
            WinPrint.close()


        },

        lodaInvoices(){
            var Invoice = {}
            var id
            Invoice = JSON.parse(localStorage.getItem('Invoice'))
            id = Invoice.id
            // localStorage.setItem('Invoice', JSON.stringify(Invoice))

            this.$axios
            .get(`retailer/clientservice/${id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    // console.log(res.data.data)
                    this.invoiceData = res.data.data
                    
                })
        },
        loadUserApi(){
            this.$axios
            .post(`retailer/user`,{}, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
                .then(res => {
                    // console.log(res.data)
                    this.userData = res.data
                    
                })
        }
    },
    created() {
        if (this.$route.meta.redirectIfLoggedIn == 'true' && localStorage.getItem('accessToken') == null) {
            this.$router.push({ name: 'Login' })
        } else {
            this.lodaInvoices()
            this.loadUserApi()
        }
    },
}
</script>

<style scoped>
@media print {
    body {
        -webkit-print-color-adjust: exact;
    }

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-11 {
        width: 91.66666666666666%;
    }

    .col-sm-10 {
        width: 83.33333333333334%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-8 {
        width: 66.66666666666666%;
    }

    .col-sm-7 {
        width: 58.333333333333336%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-5 {
        width: 41.66666666666667%;
    }

    .col-sm-4 {
        width: 33.33333333333333%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-2 {
        width: 16.666666666666664%;
    }

    .col-sm-1 {
        width: 8.333333333333332%;
    }
}
</style>
